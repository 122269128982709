<template>
  <div v-if="!loading">
    <Card :title="title" :disabled="!editMode">
      <template #close>
        <a @click.prevent="goBack">
          <v-btn icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </a>
      </template>
      <template #actions>
        <v-btn
          v-permission="{
            permissions: ['campaigns-update'],
          }"
          @click="editMode = !editMode"
        >
          <span>{{ editMode ? "Görüntüle" : "Düzenle" }}</span>
        </v-btn>
      </template>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-card flat class="pa-4">
          <v-container>
            <h2 class="mb-4">Bilgiler</h2>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  hide-details="auto"
                  v-model="currentCampaign.name"
                  label="Kampanya Adı"
                  :rules="[
                    (v) => !!v || 'Kampanya adı alanı boş bırakılamaz',
                    (v) =>
                      v?.length >= 3 ||
                      'Kampanya adı alanı en az 3 karakterden oluşmalıdır',
                  ]"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  hide-details="auto"
                  v-model="currentCampaign.code"
                  label="Kampanya Kodu"
                  :rules="[(v) => !!v || 'Kampanya kodu alanı boş bırakılamaz']"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <SMChannelSelectbox
                  :required="true"
                  :value="currentCampaign.channel_id"
                  @update:value="
                    (channel_id) => (currentCampaign.channel_id = channel_id)
                  "
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  hide-details="auto"
                  v-model="currentCampaign.url"
                  disabled
                  :messages="currentCampaign.url"
                  label="URL"
                >
                  <template #message>
                    <div v-show="validUrl">
                      <v-icon small>mdi-link</v-icon>
                      <a :href="currentCampaign.url" target="_blank">
                        {{ currentCampaign.url }}
                      </a>
                    </div>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="currentCampaign.description"
                  hide-details="auto"
                  label="Açıklama"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-content-end">
                  <v-btn class="mr-3" color="error" @click="reset">
                    İptal
                  </v-btn>
                  <v-btn
                    class="mr-3"
                    color="primary"
                    :disabled="!isFormValid"
                    @click="save"
                  >
                    Kaydet
                  </v-btn>
                  <v-btn
                    color="primary"
                    :disabled="!isFormValid"
                    @click="saveAndClose"
                  >
                    Kaydet ve Kapat
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-form>
    </Card>
  </div>
</template>

<script>
import { validateUrl } from "@/core/helper";
import { mapActions, mapGetters } from "vuex";
import SMChannelSelectbox from "@/view/content/siliconmade/SM-Channel-Selectbox.vue";

import {
  GET_CAMPAIGN,
  UPDATE_CAMPAIGN,
  CREATE_CAMPAIGN,
} from "@/core/services/store/definitions/campaigns.module";
import Card from "@/view/content/components/Card";
export default {
  components: { Card, SMChannelSelectbox },
  data() {
    return {
      editMode: false,
      currentCampaign: {},
      loading: !!this.$route.params.id,
      isFormValid: true,
    };
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.getCampaign(this.$route.params.id)
        .then(() => {
          this.currentCampaign = Object.assign({}, this.campaign);
        })
        .finally(() => {
          this.loading = false;
        });
    }

    this.$route.query.editable
      ? (this.editMode = true)
      : (this.editMode = false);
  },
  methods: {
    ...mapActions([GET_CAMPAIGN, UPDATE_CAMPAIGN, CREATE_CAMPAIGN]),
    async save(replaceRoute = true) {
      const valid = this.$refs.form.validate();
      if (!valid) {
        this.isFormValid = false;
        return;
      }
      if (this.$route.params.id) {
        try {
          const campaign = JSON.parse(JSON.stringify(this.currentCampaign));
          const data = {
            ...campaign,
            id: this.$route.params.id,
          };
          await this.updateCampaign(data);
          this.$toast.success("Kampanya güncellendi", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;
          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      } else {
        try {
          const campaign = await this.createCampaign(this.currentCampaign);
          if (replaceRoute) {
            this.$router.push({
              name: "def-campaigns-edit",
              params: {
                id: campaign.id,
              },
              query: { editable: true },
            });
          }
          this.$toast.success("Kampanya yaratıldı", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;
          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      }
    },
    async saveAndClose() {
      await this.save(false);
      if (this.isFormValid && !this.stopExecution)
        this.$router.push({ name: "def-campaigns" });
      this.stopExecution = false;
    },
    reset() {
      if (this.$route.params.id) {
        this.currentCampaign = Object.assign({}, this.campaign);
      } else {
        this.currentCampaign = {};
      }
      this.$router.push({ name: "def-campaigns" });
    },
    goBack() {
      if (
        this.$router.history.current.path.includes("definitions/campaigns/edit")
      ) {
        this.$router.push({ name: "def-campaigns" });
      } else {
        this.$router.go(-1);
      }
    },
  },
  computed: {
    ...mapGetters(["campaign"]),
    validUrl() {
      return validateUrl(this.currentCampaign.url);
    },
    title() {
      if (this.$route.params.id) {
        if (this.editMode) return "Kampanya Düzenle";
        return "Kampanya Detay";
      }
      return "Kampanya Yaratma";
    },
  },
};
</script>

<style>
.campaign--image-container {
  height: 100%;
  width: 100%;
  border: 3px dashed #ccc;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  user-select: none;
}

.campaign--image-placeholder {
  color: #a1a1a1e0;
}
</style>
